import { createStore, applyMiddleware, combineReducers } from "redux";

import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import app from "./app";
import errorPopup from "./errorPopup";
import sideNav from "./sideNav";
import confirmModal from "./confirmModal";
import askChangelogModal from "./askChangelogModal";
import search from "./search";
import login from "./login";
import users from "./users";
import usersAI from "./usersAI";
import user from "./user";
import usersUnits from "./usersUnits";
import usersUnitsTypes from "./usersUnitsTypes";
import news from "./news";
import unpublishedNews from "./unpublishedNews";
import newsArticleEditor from "./newsArticleEditor";
import projectsNews from "./projectsNews";
import projectsNewsCategories from "./projectsNewsCategories";
import projectsNewsCategory from "./projectsNewsCategory";
import projectsNewsArticleEditor from "./projectsNewsArticleEditor";
import cultureDocs from "./cultureDocs";
import unpublishedCultureDocs from "./unpublishedCultureDocs";
import docsTeamIndex from "./docsTeamIndex";
import teamDocsFolderIndex from "./teamDocsFolderIndex";
import teamDocsWithoutFolder from "./teamDocsWithoutFolder";
import docEditor from "./docEditor";
import docsProjectsCategories from "./docsProjectsCategories";
import docsProjectsCategory from "./docsProjectsCategory";
import docsCommonProcess from "./docsCommonProcess";
import docsFolders from "./docsFolders";
import docsFolderEditor from "./docsFolderEditor";
import docsSearch from "./docsSearch";
import projectsNewsSearch from "./projectsNewsSearch";
import iSpringUsers from "./iSpringUsers";
import iSpringCourses from "./iSpringCourses";
import iSpringUserResults from "./iSpringUserResults";
import editorReducer from "./textEditor";
import ai from './ai';

const rootReducer = combineReducers({
  appService: app.reducer,
  sideNavService: sideNav.reducer,
  confirmModalService: confirmModal.reducer,
  askChangelogModalService: askChangelogModal.reducer,
  loginService: login.reducer,
  errorPopupService: errorPopup.reducer,
  searchService: search.reducer,
  usersService: users.reducer,
  usersAIService: usersAI.reducer,
  usersUnitsService: usersUnits.reducer,
  usersUnitsTypesService: usersUnitsTypes.reducer,
  userService: user.reducer,
  newsService: news.reducer,
  unpublishedNewsService: unpublishedNews.reducer,
  newsArticleEditorService: newsArticleEditor.reducer,
  projectsNewsService: projectsNews.reducer,
  projectsNewsCategoriesService: projectsNewsCategories.reducer,
  projectsNewsCategoryService: projectsNewsCategory.reducer,
  projectsNewsArticleEditorService: projectsNewsArticleEditor.reducer,
  cultureDocsService: cultureDocs.reducer,
  unpublishedCultureDocsService: unpublishedCultureDocs.reducer,
  docsTeamIndexService: docsTeamIndex.reducer,
  docEditorService: docEditor.reducer,
  docsProjectsCategoriesService: docsProjectsCategories.reducer,
  docsProjectsCategoryService: docsProjectsCategory.reducer,
  docsCommonProcessService: docsCommonProcess.reducer,
  docsFoldersService: docsFolders.reducer,
  docsFolderEditorService: docsFolderEditor.reducer,
  docsSearchService: docsSearch.reducer,
  projectsNewsSearchService: projectsNewsSearch.reducer,
  teamDocsFolderIndexService: teamDocsFolderIndex.reducer,
  teamDocsWithoutFolderService: teamDocsWithoutFolder.reducer,
  iSpringUsersService: iSpringUsers.reducer,
  iSpringCoursesService: iSpringCourses.reducer,
  iSpringUserResultsService: iSpringUserResults.reducer,
  editorService: editorReducer.reducer,
  aiService: ai.reducer,
});

function* rootSaga() {
  yield all([
    login.sagas(),
    errorPopup.sagas(),
    users.sagas(),
    usersAI.sagas(),
    usersUnits.sagas(),
    usersUnitsTypes.sagas(),
    user.sagas(),
    news.sagas(),
    unpublishedNews.sagas(),
    newsArticleEditor.sagas(),
    projectsNews.sagas(),
    projectsNewsCategories.sagas(),
    projectsNewsCategory.sagas(),
    projectsNewsArticleEditor.sagas(),
    cultureDocs.sagas(),
    unpublishedCultureDocs.sagas(),
    docsTeamIndex.sagas(),
    docEditor.sagas(),
    docsProjectsCategories.sagas(),
    docsProjectsCategory.sagas(),
    docsCommonProcess.sagas(),
    docsFolders.sagas(),
    docsFolderEditor.sagas(),
    docsSearch.sagas(),
    projectsNewsSearch.sagas(),
    teamDocsFolderIndex.sagas(),
    teamDocsWithoutFolder.sagas(),
    iSpringUsers.sagas(),
    iSpringCourses.sagas(),
    iSpringUserResults.sagas(),
    ai.sagas(),
  ]);
}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
